import React, {useState, useEffect, useCallback} from 'react';
import "mapbox-gl/dist/mapbox-gl.css";
import Map, {NavigationControl, Source, Layer} from 'react-map-gl';
import { useParams } from "react-router-dom";

import cities from '../cities.json';

import {History} from './History.tsx';

import dates from '../data/dates.json';

import { cellToBoundary, latLngToCell } from 'h3-js';
import {ReactComponent as QuestionMark} from '../question-circle.svg';
import {Disclaimer} from './Disclaimer';

import './ApartmentsHeatmap.css';

function colorFromPrice(value) {
  const maxValue = 10000;

  const normalizedValue = Math.min(Math.max(value, 0), maxValue); // Ensure value is between 0 and maxValue
  const ratio = normalizedValue / maxValue;

  let red, green, blue;

  if (ratio < 0.5) {
    red = Math.floor(255 * (ratio * 2));
    green = 255;
  } else {
    red = 255;
    green = Math.floor(255 * ((1 - ratio) * 2));
  }

  blue = 0;

  return `rgb(${red}, ${green}, ${blue})`; 
}


const data = {};

async function loadData(resolution, date) {
  if (!data[date] || !data[date][resolution]) {
    const url = `https://meters.today/data/${date}/res${resolution}.json`;
    const response = await fetch(url).then(r => r.json());
    if (!data[date]) {
      data[date] = {};
    }
    data[date][resolution] = response;
  }
  return data[date][resolution];
}

function getResolutionForZoomLevel(zoomLevel, date) {
  if (zoomLevel > 15) {
    return 10;
  }

  if (zoomLevel > 13.5) {
    return 9;
  }

  if (zoomLevel > 12) {
    console.log('here!', zoomLevel);
    return 8;
  }

  if(zoomLevel > 10.5) {
    
    console.log('and here', zoomLevel)
    return 7;
  }

  if(zoomLevel > 9) {
    return 6;
  }
  if(zoomLevel > 8) {
    return 5;
  }
  if(zoomLevel > 7) {
    return 4;
  }
  return 3;
}

const latestDate = dates.sort((a, b) => {
  return a < b ? 1 : -1;
})[0];

const INITIAL_VIEW_STATE = {
  latitude: 60.192059,
  longitude: 24.945831,
  zoom: 11,
  bearing: 0,
  pitch: 0,
};

export default function ApartmentsHeatmap() {
  const [date, setDate] = useState(latestDate);
  const { cityName } = useParams();

  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);

  const [mapZoomLevel, setMapZoomLevel] = useState(11);
  const [data, setData] = useState({});
  const [res, setRes] = useState(7);
  const [historyId, setHistoryId] = useState();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cityTitle, setCityTitle] = useState(undefined);
  const keys = Object.keys(data);

  const load = useCallback(async () => {
    const resolution = getResolutionForZoomLevel(mapZoomLevel);
    setRes(resolution);
    const response = await loadData(resolution, date);
    setData(response);
  }, [mapZoomLevel, date]);

  useEffect(() => {
    if (!cityName) {
      return;
    }

    const city = cities.find(c => c.name === cityName);
    if (!city) {
      return;
    }

    setCityTitle(city.title);
    setViewState(city.viewState);
    setRes(getResolutionForZoomLevel(city.viewState.zoom));
    setMapZoomLevel(city.viewState.zoom);
  }, [cityName]);

  useEffect(() => {
    load();
  }, [mapZoomLevel, date, load]);

  function getFeatures() {
    return keys.map(key => {
      const {pricePerSqm, count} = data[key];
      return {
        type: 'Feature',
        properties: {
          color: colorFromPrice(pricePerSqm),
          opacity: 0.5 * Math.min(count/10, 1),
          text: `${pricePerSqm.toFixed(0)} €/m2\n${count} ads`,
          id: key,

        },
        geometry: {
          type: 'Polygon',
          coordinates: [cellToBoundary(key, true)]
        }
      };
    });
  }

  function onZoomEnd(newZoom) {
    setMapZoomLevel(newZoom.viewState.zoom);
    setHistoryId();
  }

  function onMoveEnd(e) {
    setViewState(e.viewState);
  }

  function onSelectDate(e) {
    setDate(e.target.value);
  }

  function closeHistory() {
    setHistoryId(undefined);
  }

  function onMapClick(event) {
    const { lngLat } = event;
    if (res > 9) {
      return;
    }
    const cell = latLngToCell(lngLat.lat, lngLat.lng, res);
    if (!data[cell]) {
      return;
    }
    setHistoryId(cell);
  }

  const features = getFeatures();

  return <div className="ApartmentsHeatmap">
    <div className="ApartmentsHeatmap__head">
      <div className="select-date">
        <select value={date} onChange={onSelectDate}>
          {dates.map(d => {
            return <option key={d} value={d}>{d}</option>
          })}
        </select>
      </div>
      <div className="ApartmentsHeatmap__disclaimer" onClick={() => {setShowDisclaimer(true)}}><QuestionMark /></div>
    </div>

    <Map
      {...viewState}
      projection="globe"
      onClick={onMapClick}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      mapboxAccessToken="pk.eyJ1IjoiYWxleGVpbW9pc3NlZXYiLCJhIjoiY2xreTJrZGRlMTd6NDNkcG16bTVnbmxjZiJ9.QJqrTtj51RKKpITnM6pjKA"
      style={{
        height: "100vh",
        width: "100vw",
      }}
      onZoomEnd={onZoomEnd}
      onMove={onMoveEnd}
    >
      <Source type="geojson"
                data={{
                  type: "FeatureCollection",
                  features: features
                }}>
        <Layer id="polyline-layer" type="fill" paint={{
          'fill-outline-color': 'white',
          "fill-color": ["get", "color"],
          "fill-opacity": ["get", "opacity"],
        }}/>

        <Layer type="symbol" layout={{
          "text-field": ['get', 'text'],
          "text-size": 11,
        }} />
      </Source>
      <NavigationControl />
    </Map>
    {historyId && <History
      id={historyId}
      onClose={closeHistory}
      count={data[historyId].count}
      pricePerSqm={data[historyId].pricePerSqm}
      resolution={res}
      />}
    {showDisclaimer && <Disclaimer onClose={() => {setShowDisclaimer(false)}} />}
  </div>;
}
